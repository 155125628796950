import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { IconButton, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export const MobileNavBarButtons = () => {
    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={isOpen ? <CloseIcon/> : <HamburgerIcon /> }
                        variant="outline"
                    />
                    <MenuList>
                        <MenuItem as='a' href="/">Home</MenuItem>
                        <MenuItem as='a' href="/about">About</MenuItem>
                    </MenuList>
                </>
            )}
        </Menu>
    );
}