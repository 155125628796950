
import {
    ChakraProvider,
    Container,
} from "@chakra-ui/react";
import theme from "./assets/themes/theme";
import ReactGA from 'react-ga';
import { App } from "./App";
      
export const Root = () => {
        
    const TRACKING_ID = "G-GF0SLGWRR8";
    ReactGA.initialize(TRACKING_ID);
      
  
    return (
        <ChakraProvider theme={theme}>
            <Container maxW={'3xl'}>
                <App/>
            </Container>
        </ChakraProvider>
    );
}