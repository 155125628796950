import { Box, Text } from '@chakra-ui/react';
import { MovieType } from "../../../types/MovieType.d";
import { Movie } from './Movie';
import { MovieList } from './MovieList';


export const MovieDisplay = (props: {
    shouldShowList:boolean,
    movie: MovieType,
    movies: Array<MovieType>,
    isLoading: boolean
}) => {
    var hasMovieList = props.movies.length !== 0;
    const displayCorrectComponent = () => {
        if (props.isLoading) {
            return <Text>Fetching Movie List...</Text>;
        }
        if (props.shouldShowList) {
            return <MovieList movies={props.movies} />;
        }
        if (hasMovieList && props.movie) {
            return <Movie movie={props.movie} />;
        }
        return <Text>We're having problems fetching movies. Please check your internet connection.</Text>;
    }
    return (
        <Box>
            {displayCorrectComponent()}
        </Box>
    );
}