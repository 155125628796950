import { Stack } from '@chakra-ui/react';
import { HeaderButtons } from './HeaderButtons';
import { ListAction } from './ListAction';

export const HeaderActions = (props: { 
    handleMainActionClick: () => void,
    handleApplyClick: Function,
    shouldShowList:boolean, 
    toggleList:() => void
}) => {
    return (
        <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            <HeaderButtons handleMainActionClick={props.handleMainActionClick} handleApplyClick={props.handleApplyClick}/>
            <ListAction shouldShowList={props.shouldShowList} toggleList={props.toggleList}/>
        </Stack>
    );
}