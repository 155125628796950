import { Text, Card, CardBody, Heading, Flex, Spacer, Center, HStack, Link } from "@chakra-ui/react";
import { MovieType } from "../../../types/MovieType.d";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const Movie = (props: {movie: MovieType}) => {

    return (
        <Card size='md' mb='0.5rem'>
            <CardBody>
                <Heading size='md'>
                    <Link href={props.movie.link} isExternal>
                        {props.movie.title} <ExternalLinkIcon viewBox="0 0 32 32" mx='2px' />
                    </Link>
                </Heading>
                <Center>
                <HStack>
                        <Text fontSize='sm'>{props.movie.year}</Text>
                        <Spacer />
                        <Text fontSize='sm'>{props.movie.runtime}</Text>  
                        <Spacer />
                        <Text fontSize='sm'>{props.movie.rating}</Text>
                </HStack>
                </Center>
                <Text>{props.movie.description}</Text>
            </CardBody>
        </Card>
    );
};