import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react"

export const AboutPage: React.FC = () => {
    return (
        <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 8 }}>
            <Heading
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}>
                    About What to Watch
            </Heading>
            <Text>
                What to Watch is an app that helps you find a movie to watch. No more time wasted scrolling through movie lists. We grab the top 50 movies based on your filters and show you a random movie from that list. You can also view the list of 50 films that the movie is being selected from.
            </Text>
            <Text>
                This app was created by <Link href="github.com/SLMassari">Sam Massari</Link> to make picking a movie easier, as well as get some practice in a few technologies. This site uses Python/Flask for the API server, Typescript/React/ChakraUI for the front end, as well as Redis for caching. The app is hosted on Render using their blueprint deployments.
            </Text>
        </Stack>
    );
}