import { Button, Stack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export const NavBarButtons = () => {
    return (
        <Stack 
            direction='row' 
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            flexGrow={1}
            >
            <Button 
                as={RouterLink} 
                to="/" 
                variant="link" 
                colorScheme="black"
                mr={ 4 }
                mb={0 }
                w='auto'
                >
                Home
            </Button>
            <Button 
                as={RouterLink} 
                to="/about" 
                variant="link" 
                colorScheme="black"
                w='auto'
                >
                About
            </Button>
        </Stack>
    );
}