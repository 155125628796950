import { FilterOptions } from "../types/FilterOptions.d";

export const movieListUrlGenerator = (filters: FilterOptions) => {
    var url:string = "https://flaskapi-r6av.onrender.com/movies"
    const currentYear = new Date().getFullYear();
    var isFirstFilter = true;

    const addCorrectSeparator = (url: string) => {
        if (isFirstFilter) {
            isFirstFilter = false;
            return url + "?";
        }
        return url + "&";
    }

    if (filters.genre !== "") {
        url = addCorrectSeparator(url);
        url += "genres=" + filters.genre.toLowerCase();
    }
    if (filters.minYear !== 1950 || filters.maxYear !== currentYear) {
        url = addCorrectSeparator(url);
        url += "release_date="
        if (filters.minYear !== 1950) {
            url += filters.minYear +   "-01-01";
        }
        url += ",";
        if (filters.maxYear !== new Date().getFullYear()) {
          url += filters.maxYear + "-12-31";
        }
    }
    if (filters.minRuntime !== 1 || filters.maxRuntime !== 240) {
        url = addCorrectSeparator(url);
        url += "runtime=" ;
        if (filters.minRuntime !== 1) {
            url += filters.minRuntime;
        }
        url += ",";
        if (filters.maxRuntime !== 240) {
            url += filters.maxRuntime;
        }
    }
    return url;
}