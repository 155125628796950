import { Box, Flex, IconButton, Image, Spacer, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import logo from "../../assets/logo_black.png";
import { NavBarButtons } from "./NavBarButtons";
import { MobileNavBarButtons } from "./MobileNavBarButtons";

export const NavBar: React.FC = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    return (
        <Flex
            min-width='max-content'
            alignItems='center'
            gap={2}
            >
            <Box p='2'>
                <Image
                    src={logo}
                    alt="Logo"
                    w="150px"
                    h="auto"
                />
            </Box>
            <Spacer />
            { !isMobile && <NavBarButtons /> }
            { isMobile && <MobileNavBarButtons />}
        </Flex>
    );
}