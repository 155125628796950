import { useState, useEffect } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { FilterOptions } from '../../types/FilterOptions.d';
import { MovieDisplay } from './MovieDisplay/MovieDisplay';
import { MovieType } from '../../types/MovieType.d';
import { WTWHeader } from './Header/WTWHeader';
import { movieListUrlGenerator } from '../../helpers/UrlGeneratorHelper';

export const MainPage = () => {
    const [shouldShowList, setShouldShowList] = useState<boolean>(false);
    const toggleMovieList = () => {
      if (movies.length != 0) {
        setShouldShowList(!shouldShowList);
      }
    }

    const randomNumberInRange = (min: number, max: number) => {
      return Math.floor(Math.random()
          * (max - min + 1)) + min;
    };

    const [filters, setFilters] = useState<FilterOptions>({
        minYear: 1950,
        maxYear: new Date().getFullYear(),
        minRuntime: 1,
        maxRuntime: 240,
        genre: ""
      });
    
    const applyFilters = (filters: FilterOptions) => { 
        setFilters(filters);
    }

    const [movie, setMovie] = useState<MovieType>({
      title: "",
      year: 0,
      runtime: "",
      rating: "",
      description: "",
      link: ""
    });

    const getRandomMovie = () => {
      const randomIndex = randomNumberInRange(0, movies.length - 1);
      if (shouldShowList) {
        toggleMovieList();
      }
      setMovie(movies[randomIndex]);
    }
  
    const [movies, setMovies] = useState<Array<MovieType>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    const getMovieList = () => {
        const url:string = movieListUrlGenerator(filters);
        setIsLoading(true);
        fetch(url).then((res) => 
            res.json().then((data) => {
                setMovies(data);
                setIsLoading(false);
            })
        );
    }

    useEffect(() => {
      getMovieList();
    }, [filters]);

    useEffect(() => {
      getRandomMovie();
    }, [movies]);

    return (
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 8 }}>
          <WTWHeader 
            handleMainActionClick={getRandomMovie}
            handleApplyClick={applyFilters}
            shouldShowList={shouldShowList}
            toggleList={toggleMovieList} 
            />
          <MovieDisplay 
            shouldShowList={shouldShowList} 
            movie={movie} 
            movies={movies} 
            isLoading={isLoading} 
            />
        </Stack>
    );
}
