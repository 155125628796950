import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  styles: {
    global: (props: Record<string, any> | StyleFunctionProps) => ({
      body: {
        bg: mode("#F5F5F5", "#F5F5F5")(props), // Use the same color for both light and dark modes
      },
    }),
  },
});

export default theme;