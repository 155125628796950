import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { useState } from "react"

export const IntegerInput = (props: {defaultValue: number, min: number, max: number, setValue: Function}) => {

    return (
        <NumberInput
            onChange={(_, value) => props.setValue(value)}
            defaultValue={props.defaultValue}
            max={props.max}
            min={props.min}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
      )
}