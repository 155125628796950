import { Heading, Text } from '@chakra-ui/react';

export const TitleHeader = () => {
    return (
        <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            What to <br />
            <Text as={'span'} color={'green.400'}>
                Watch
            </Text>
        </Heading>
    );
}