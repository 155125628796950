import React, { useState } from 'react';
import {
    Box,
    HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
} from '@chakra-ui/react';
import { IntegerInput } from './IntegerInput';
import { GenreSelect } from './GenreSelect';
import { FilterOptions } from '../../../types/FilterOptions.d';

export const FilterModal = (props: { isOpen : boolean, onClose: () => void , applyFilters: Function }) => {
    const currentYear = new Date().getFullYear();
    const firstYear = 1950;
    const [yearMax, setYearMax] = useState<number>(currentYear);
    const handleYearMax = (value: number) => { setYearMax(value); }
    const [yearMin, setYearMin] = useState<number>(firstYear);
    const handleYearMin = (value: number) => { setYearMin(value); }

    const [genre, setGenre] = useState<string>('');
    const handleGenre = (value: string) => { setGenre(value); }


    const [lowestRuntime, highestRuntime] = [1, 240];
    const [runtimeMin, setRuntimeMin] = useState(lowestRuntime);
    const handleRuntimeMin = (value: number) => { setRuntimeMin(value);}
    const [runtimeMax, setRuntimeMax] = useState(highestRuntime);
    const handleRuntimeMax = (value: number) => { setRuntimeMax(value); }

  const handleApplyFilters = () => {
    const fm:FilterOptions= {
        minYear: yearMin,
        maxYear: yearMax,
        minRuntime: runtimeMin,
        maxRuntime: runtimeMax,
        genre: genre
    }
    props.applyFilters(fm);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Stack direction='column'>
                <Text>Year:</Text>
                <HStack>
                    <IntegerInput defaultValue={yearMin} min={1950} max={yearMax} setValue={handleYearMin}/>
                    <Text>To</Text>
                    <IntegerInput defaultValue={yearMax} min={yearMin} max={currentYear} setValue={handleYearMax}/>
                </HStack>
                <Text>Runtime (in minutes):</Text>
                <HStack>
                    <IntegerInput defaultValue={runtimeMin} min={lowestRuntime} max={runtimeMax} setValue={handleRuntimeMin}/>
                    To
                    <IntegerInput defaultValue={runtimeMax} min={runtimeMin} max={highestRuntime} setValue={handleRuntimeMax}/>
                </HStack>
                <Text>Genre:</Text>
                <GenreSelect value={genre} setGenre={handleGenre} />
            </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleApplyFilters}>
            Apply Filters
          </Button>
          <Button variant="ghost" onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};