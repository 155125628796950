import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainPage } from "./components/MainPage/MainPage";
import { NavBar } from "./components/NavBar/NavBar";
import { AboutPage } from "./components/AboutPage/AboutPage";

export const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
  );
};
