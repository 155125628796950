import { Box, Stack, UnorderedList } from "@chakra-ui/react";
import { Movie } from "./Movie";
import { MovieType } from "../../../types/MovieType.d";

export const MovieList = (props: {movies: Array<MovieType>}) => {

    const listItems = props.movies.map((movie) => {
        return <Movie movie={movie} />
    });
    return (<Stack direction={"column"}>{listItems}</Stack>)
}