import { Box, Stack } from '@chakra-ui/react';
import { TitleHeader } from "./TitleHeader";
import { AppDescription } from "./AppDescription";
import { HeaderActions } from "./HeaderActions";

export const WTWHeader = (props: {
    handleMainActionClick:() => void,
    handleApplyClick:Function,
    shouldShowList:boolean,
    toggleList: () => void
}) => {
    return (
        <Stack
            textAlign={'center'}
            spacing={{ base: 8, md: 6 }}>
            <TitleHeader />
            <AppDescription />
            <HeaderActions 
                handleMainActionClick={props.handleMainActionClick}
                handleApplyClick={props.handleApplyClick}
                shouldShowList={props.shouldShowList}
                toggleList={props.toggleList}
            />
        </Stack>
    );
}