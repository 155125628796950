import {Button} from '@chakra-ui/react';

export const ListAction = (props: {shouldShowList:boolean, toggleList:() => void}) => {
    return(
        <div>
            { !props.shouldShowList &&
                <Button onClick={props.toggleList} variant={'link'} colorScheme={'blue'} size={'sm'}>
                    Show me the list
                </Button>
            }
            { props.shouldShowList &&
                <Button onClick={props.toggleList} variant={'link'} colorScheme={'blue'} size={'sm'}>
                    Hide the list
                </Button>
            }
        </div>
    );
}