import { Select } from "@chakra-ui/react";

export const GenreSelect = (props: {value:string, setGenre: Function}) => {
    const genres: Array<string> = [
        "Action",
        "Adventure",
        "Animation",
        "Biography",
        "Comedy",
        "Crime",
        "Documentary",
        "Drama",
        "Family",
        "Fantasy",
        "Film-Noir",
        "Game-Show",
        "History",
        "Horror",
        "Music",
        "Musical",
        "Mystery",
        "News",
        "Reality-TV",
        "Romance",
        "Sci-Fi",
        "Short",
        "Sport",
        "Thriller",
        "War",
        "Western"
    ];

    const genreOptions = genres.map((genre) => {
        return <option key={genre} value={genre}>{genre}</option>
    });

    return (
        <Select value={props.value} placeholder='Select genre' onChange={(e) => props.setGenre(e.currentTarget.value)}>
            {genreOptions}               
        </Select>
    );
}