import { useState } from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { FilterModal } from '../Filter/FilterModal';
import { FilterOptions } from '../../../types/FilterOptions.d';

export const HeaderButtons = (props: { handleMainActionClick:() => void, handleApplyClick:Function }) => {
    const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
    const toggleModal = () => {
      setShouldShowModal(!shouldShowModal);
    }

    const handleApplyFilters = (filters: FilterOptions) => {
        props.handleApplyClick(filters);
        toggleModal();
    }
    
    return (
        <Stack direction={'row'} spacing={3}>
              <Button
                onClick={props.handleMainActionClick}
                colorScheme={'green'}
                bg={'green.400'}
                rounded={'full'}
                px={6}
                _hover={{
                  bg: 'green.500',
                }}>
                Find me a movie
              </Button>
                
              <Button
                onClick={toggleModal}
                colorScheme={'cyan'}
                bg={'cyan.400'}
                rounded={'full'}
                _hover={{
                  bg: 'cyan.500',
                }}>
                Filters
              </Button>
              <FilterModal isOpen={shouldShowModal} onClose={toggleModal} applyFilters={handleApplyFilters} />
            </Stack>
    );
}